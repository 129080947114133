<script setup lang="ts">
import rank1 from '@/assets/icons/1.png'
import rank2 from '@/assets/icons/2.png'
import rank3 from '@/assets/icons/3.png'

const props = defineProps<Props>()
interface Props {
  rank: number
}
</script>

<template>
  <div
    v-if="props.rank < 4"
    class="d-flex text-center align-center"
  >
    <span>人気順位</span>

    <VAvatar

      size="40"
      rounded="0"
    >
      <VImg
        v-if="props.rank === 1"
        cover
        :src="rank1"
      />
      <VImg
        v-if="props.rank === 2"
        cover
        :src="rank2"
      />
      <VImg
        v-if="props.rank === 3"
        cover
        :src="rank3"
      />
    </VAvatar>
    <span>位</span>
  </div>
  <div
    v-else
    class="d-flex text-center align-center"
  >
    <span>人気順位</span>
    <h4 class="text-warning m-2">
      {{ props.rank }}
    </h4>
    <span>位</span>
  </div>
</template>
